
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "default": {
    "SEO": {
      "domain": "Tastydrop.me"
    },
    "colorPalette": {
      "text": "#ffffff",
      "mainColors": {
        "primary": {
          "50": "#8e60ee",
          "100": "#713dd1",
          "200": "#512a96",
          "300": "#3f1f78",
          "400": "#331862",
          "500": "#2a1051",
          "600": "#200e3d",
          "700": "#1a0a34",
          "800": "#15072b",
          "900": "#0F0422"
        }
      },
      "skinsRarity": {
        "ultra": "#00C487",
        "arcana": "#8bcc47",
        "common": "#92a4b9",
        "immortal": "#e8c03c",
        "legendary": "#d32de6",
        "mythical": "#8847ff",
        "rare": "#4b69ff",
        "uncommon": "#5b94d3"
      },
      "userLevels": {
        "14": [
          "#737785"
        ],
        "59": [
          "#573B93"
        ],
        "200": [
          "#DDC0DA",
          "#F12393",
          "#DDC0DA"
        ],
        "1014": [
          "#7F11C2"
        ],
        "1519": [
          "#6439DE"
        ],
        "2029": [
          "#2F64ED"
        ],
        "3039": [
          "#00ABAB"
        ],
        "4049": [
          "#00B960"
        ],
        "5059": [
          "#7DA608"
        ],
        "6069": [
          "#CAA832"
        ],
        "7079": [
          "#E18700"
        ],
        "8089": [
          "#E76100"
        ],
        "9099": [
          "#EF2222"
        ],
        "100109": [
          "#F12393"
        ],
        "110119": [
          "#DC68D0"
        ],
        "120129": [
          "#DC68D0",
          "#2F64ED",
          "#8FA8E9"
        ],
        "130139": [
          "#DC68D0",
          "#00ABAB",
          "#6CCBCB"
        ],
        "140149": [
          "#DC68D0",
          "#00B960",
          "#71D1A3"
        ],
        "150159": [
          "#DC68D0",
          "#7DA608",
          "#B3CE67"
        ],
        "160169": [
          "#DC68D0",
          "#CAA832",
          "#D6C17A"
        ],
        "170179": [
          "#DC68D0",
          "#E18700",
          "#E0B77A"
        ],
        "180189": [
          "#DC68D0",
          "#E76100",
          "#FF9950"
        ],
        "190199": [
          "#DC68D0",
          "#E76100",
          "#FF9950"
        ]
      },
      "caseCard": {
        "circleBackground": "radial-gradient(50% 50% at 50% 50%, #331862 87.5%, #8E60EE 100%)",
        "circleShadow": "#713DD1"
      },
      "battlepass": {
        "classic": {
          "locked": "#1B1D2B",
          "done": {
            "colorStops": [
              "#014F25 0%",
              "#1B1D2B 54.17%"
            ],
            "toDirection": "180deg"
          },
          "level": "#33313D"
        },
        "rare": {
          "default": {
            "colorStops": [
              "#0D7831 0%",
              "#0B2421 50%",
              "#064133 100%"
            ],
            "toDirection": "180deg"
          },
          "current": {
            "colorStops": [
              "#8BCC47 0%",
              "#0C3530 50%",
              "#0E5F3D 100%"
            ],
            "toDirection": "180deg"
          },
          "shadow": "#7AC915",
          "level": {
            "colorStops": [
              "#8BCC47 0%",
              "#003939 100%"
            ],
            "toDirection": "225deg"
          }
        },
        "ultra": {
          "default": {
            "colorStops": [
              "#978430 0%",
              "#1E160F 50%",
              "#483B0E 100%"
            ],
            "toDirection": "180deg"
          },
          "current": {
            "colorStops": [
              "#E3C74A 0%",
              "#3C311E 50%",
              "#71591B 100%"
            ],
            "toDirection": "180deg"
          },
          "shadow": "#DBB023",
          "level": {
            "colorStops": [
              "#D0BA55 0%",
              "#816F20 100%"
            ],
            "toDirection": "225deg"
          }
        }
      }
    }
  },
  "event": {
    "coinIcon": "SvgoNy23Coin",
    "coinIconUrl": "https://tastydrop.cc/img/event/xmas23/newCoin.svg",
    "textCoinsColor": "#FF4853",
    "buttonsColors": {
      "mainGradient": [
        "#3592E8",
        "#00C9B1"
      ]
    }
  },
  "nuxt": {
    "buildId": "5df79035-e36a-453c-906d-f4c3e4bf7acb"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
